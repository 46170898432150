<template>

<div class="container py-3">
    <div class="row" v-if="!pageloader">
      <div class="col-12 col-md-8 order-lg-1 order-md-1 order-2">
        <div class="mt-lg-0 mt-2">
          <div class="d-lg-flex d-none justify-content-between align-items-start mb-3">
            <div class="fw-bold">Request Your Seats (Optional)</div>
          </div>

          <div class="col-12">
            <div class="theme-box-shadow theme-border-radius bg-white mb-3 p-3 pb-0">
            
              <div v-if="totalSeat.length > 0" class="py-3">
                <div class="mt-3">
                  <div class="d-flex flex-wrap">
                    <div
                      class="pe-2 mt-1"
                      v-for="(data, index) in totalSeat"
                      :key="index"
                    >
                      <v-btn
                        variant="outlined"
                        :class="
                          flightSelect == index ? 'tru-flight' : 'tru-flightfalse'
                        "
                        @click="selectdata(data, index)"
                      >
                        <div>{{ data.origin }}-{{ data.destination }}</div>
                      </v-btn>
                    </div>
                  </div>
                
                  <!-- <div class="d-flex flex-wrap mt-2">
                    <div
                      class="pe-2 mt-1"
                      v-for="(data, index) of totalpassanger"
                      :key="index"
                    >
                      <v-btn
                        style="font-size: 13px"
                        variant="outlined"
                        @click="totalpassData(data, index)"
                        :class="passSelect == index ? 'tru-pass' : ''"
                      >
                        Passenger{{ index + 1 }}</v-btn
                      >
                    </div>
                  </div> -->
                
                <div style="overflow-x: scroll">
                  <table>
                        <th>Passenger Details</th>
                        <!-- <div  v-for="(data, index) in totalSeat" :key="index">
                        <div style="visibility: hidden;">
                        flight{{index+1}}
                        </div>
                        </div> -->
                      
                      <tr v-for="(data, index) of totalpassanger" :key="index">
                        {{console.log(data,'ppp00000')}}
                        <td v-if="data.type !== 'Infrant'">
                          <div>
                            <span>
                              <span class="text-uppercase"
                          style="font-size: 15px;font-weight:500;cursor:pointer;"
                          variant="outlined" :class="{ selected: index === passSelect, not: index !== passSelect }"
                          @click="totalpassData(data, index)"
                         >
                         {{ index + 1 }}. {{ data.name}}</span>
                            </span>
                          </div>
                        </td>
                        <td v-for="(seat, index) of data.seat_number" :key="index">
                          {{ console.log(seat, "seat") }}
                          <div style="font-size: 14px; letter-spacing: 1px">
                            {{ seat == null ? '-' : seat }}
                          </div>
                        </td>
                      </tr>
                  </table>
                </div> 
              
              
              
              
                  <div
                    style="overflow-x: scroll; border: 1px solid lightgrey"
                    class="mt-2 pa-2" v-if="totalSeat[indexData].cabins && totalSeat[indexData].cabins[0] && totalSeat[indexData].cabins[0].columns"
                  >
                    <div
                      v-for="(data, index) in totalSeat[indexData].cabins[0].columns"
                      :key="index"
                      class="d-flex mt-2"
                    >
                      <div class="px-1 text-center">
                        <div style="width: 25px; font-weight: 500">
                          {{ data.column }}
                        </div>
                      </div>
                      <div
                        class="px-1 text-center"
                        v-for="(datas, index1) in totalData[data.column]"
                        :key="index1"
                      >
                        <div>
                          <div
                            :class="{
                              'false-sheet': datas.seats[0].occupied === 'true',
                              'true-sheet': datas.seats[0].occupied === 'false',
                              'tru-icon':
                                datas.seats[0].occupied === 'false' && datas.selectedPass,
                            }"
                            @click="seatSelectdata(datas, index1, data.column)"
                          >
                            <template v-if="datas.selectedPass">
                              <span class="passenger-number" style="color:white;padding:3px;">{{ datas.selectedPass }}</span>
                            </template>
                            <template v-else>
                              <v-icon
                                :color="datas.seats[0].occupied === 'false' ? 'white' : 'white'"
                                size="15"
                              >
                                mdi-seat-passenger
                              </v-icon>
                            </template>
                          <v-tooltip activator="parent" location="top" v-if="datas.seats[0].chargeable === 'true' & datas.seats[0].occupied === 'false'">
                            <span>Price: {{ datas.seats[0].currency }} {{ datas.seats[0].price == '-' ? '0.00' : datas.seats[0].price }}</span>
                          </v-tooltip>
                          </div>
                        </div>
                      
                        <div>
                          <div v-if="data.space" style="font-size: 13px">
                            {{ datas.row_number }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
                <div
                  class="d-flex justify-content-between flex-wrap mt-2 py-2"
                  style="border: 1px solid lightgray"
                >
                  <div class="d-flex flex-wrap m-1nt" style="font-size: 13px">
                    <div class="d-flex ms-1">
                      <div class="av-sheet"></div>
                      <div class="mt-1">Available Seat</div>
                    </div>
                    <div class="d-flex ms-2">
                      <div class="oc-sheet"></div>
                      <div class="mt-1">Occupied Seat</div>
                    </div>
                    <div class="d-flex ms-2">
                      <div class="sel-sheet"></div>
                      <div class="mt-1">Selected Seat</div>
                    </div>
                  </div>
                  <div class="pe-2">
                    <span>Total Seat Request Price: <span class="fw-bold">{{ metaCurrency }} {{ totalSeatPrice.toFixed(2) }}</span></span>
                  </div>
                </div>
              
              </div>
            
            </div>
          </div>

          <div class="row d-flex justify-content-between align-center mb-md-0 mb-2">
            <div class="col-md-4 col-12 d-flex justify-md-start justify-center mb-md-0 mb-2">
                <!-- <button @click="submitform" type="submit" class="btn btn-effect btn-book px-5">{{ $t("flightInfoContent.contents.continue") }}</button> -->
                <v-btn
                  width="auto"
                  height="40px"
                  class="continue-btn"
                  type="submit"
                  style="color: white;text-transform: capitalize;background-color: #26225f;
                    font-size:16px;" @click="onContinue">Continue</v-btn>
            </div>
            <div class="col-md-8 col-12 d-flex justify-md-end justify-center">
              <v-btn
                width="auto" height="40px" class="continue-btn" type="submit"
                style="color: white;text-transform: capitalize;background-color: #26225f;font-size:16px;"
              @click="submitform">Skip for Seat Selection</v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-3 fareDetails order-lg-2 order-md-2 order-1">
          <div class="fare-summery" :style="{ top: topSticky }">
            <v-card
              max-width="400px"
              max-height="500px"
              class="m-auto"
              style="border: 1px solid lightgrey"
            >
              <div class="p-2" style="background-color: #d3d3d373">
                <h5 class="pa-2 py-2 m-0">
                  {{ $t("fareDetContent.fareDetails") }}
                </h5>
              </div>

              <div class="pa-3">
                <div class="d-flex justify-content-between">
                  <!-- <div>
                    <p class="m-0" style="font-size: 16px; font-weight: 500">
                      Passenger
                    </p>
                  </div> -->
                  <div>
                    <p class="m-0" style="font-size: 16px; font-weight: 500">
                      {{ $t("fareDetContent.totalPrice") }}
                    </p>
                  </div>
                  <div>
                    <p class="tot-price m-0">
                      {{ currency }} {{ totalAmountpassenger }}
                    </p>
                  </div>
                </div>
                <!-- <hr /> -->
                <div class="row mt-2">
                  <div class="col-md-12">
                    <div class="row justify-content-between">
                      <div class="col-md-5">
                        <span class="f-size-14 fw-500">{{
                          $t("fareDetContent.passengers")
                        }}</span>
                      </div>
                      <!-- <div class="col-md-4"></div> -->
                      <div class="col-md-7 f-size-14 text-end">
                        <span v-if="adultLength > 0"
                          >{{ adultLength }} x Adt</span
                        >
                        <span v-if="childLength > 0"
                          >, {{ childLength }} x Chd</span
                        >
                        <span v-if="infLength > 0 "
                          >, {{ infLength }} x Inf</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <hr> -->
                <div>
                  <hr class="mt-1 mb-2" />
                  <div>
                    <div class="d-flex align-end pb-2 border-2">
                      <span
                        class="not-collapsed f-size-14"
                        data-bs-toggle="collapse"
                        href="#collapse-Example"
                        aria-expanded="true"
                        aria-controls="collapse-Example"
                        @click="isIcon = !isIcon"
                        style="
                          padding-right: 5px;
                          cursor: pointer;
                          letter-spacing: 1px;
                        "
                      >
                        <span class="fw-600"
                          >{{ $t("fareDetContent.fareSummary") }}
                          <v-icon
                            size="18"
                            class="not-collapsed ms-1"
                            data-bs-toggle="collapse"
                            href="#collapse-Example"
                            aria-expanded="true"
                            aria-controls="collapse-Example"
                          >
                            {{
                              isIcon
                                ? "mdi-arrow-up-drop-circle-outline"
                                : "mdi-information-outline"
                            }}</v-icon
                          >
                        </span>
                      </span>
                    </div>

                    <div
                      v-for="(res, index) of getDataResult"
                      :key="index"
                      id="collapse-Example"
                      class="collapse bordeer-top-0 border border-2 border-top-0"
                    >
                      <div class="comDetailSection">
                        <div class="paxpricesection">
                          <table class="table table-borderless mb-0">
                            <thead>
                              <tr class="f-size-12 fw-500" style="font-size:12px;">
                                <td style="background: #dee2e6">Pax</td>
                                <td style="background: #dee2e6">Base</td>
                                <td style="background: #dee2e6">Tax</td>
                                <td style="background: #dee2e6">Per Person</td>
                              </tr>
                            </thead>
                            <tbody class="f-size-10 fw-400" style="font-size:10px;">
                              {{
                                console.log(res, "priceDetailllll")
                              }}
                              <template v-for="(priceDetail, paxindex) in res.priceDetails" :key="paxindex">
                                <tr v-if="priceDetail && priceDetail.pax_type && priceDetail.baseprice && priceDetail.taxprice">
                                  <td>{{ translatePaxType(priceDetail.pax_type) }}</td>
                                  <td>{{ priceDetail.baseprice }}</td>
                                  <td>{{ priceDetail.taxprice }}</td>
                                  <td>{{ getperpersonPrice(priceDetail) }}</td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr class="mt-1 mb-2" />

                  <div class="p-1">
                    <div class="row">
                      <div class="col-6 f-size-14">
                        {{ $t("fareDetContent.basePrice") }}<br />
                        {{ $t("fareDetContent.taxesFees") }}
                      </div>
                      <div class="col-6 f-size-14">
                        <div class="d-flex flex-row justify-end">
                          <div class="col-5 text-end pe">
                            {{ currency }} <br />
                            {{ currency }}
                          </div>
                          <div class="col-7 text-end pe-2">
                            {{ totalbasePassanger }}<br />
                            {{ totalTaxPassanger }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr class="mt-1 mb-2" />

                  <div class="d-flex align-end mb-2">
                    <span
                      class="not-collapsed"
                      style="
                        padding-right: 5px;
                        cursor: pointer;
                        font-size: 14px;
                        letter-spacing: 1px;
                      "
                    >
                      <a
                        class="baggage border-none"
                        style="
                          font-size: 14px;
                          color: rgb(13, 110, 253);
                          text-decoration: underline;
                        "
                      >
                        <span
                          data-bs-toggle="offcanvas"
                          data-bs-target="#staticBackdrop1"
                          aria-controls="staticBackdrop1"
                          @click="getFareRules()"
                          >{{
                            $t("baggageDetContent.contents.baggAndRules")
                          }}</span
                        >
                      </a>
                      <div class="drawer-section">
                        <div
                          class="offcanvas offcanvas-end"
                          data-bs-backdrop="static"
                          id="staticBackdrop1"
                          aria-labelledby="staticBackdropLabel">
                          <div
                            class="offcanvas-header d-flex justify-content-between"
                            style="
                              background: #5293cb !important;
                              color: #fff !important;">
                            <h5
                              class="offcanvas-title p-0 text-white"
                              id="staticBackdropLabel">
                              {{
                                $t("baggageDetContent.contents.baggAndRules")
                              }}
                            </h5>
                            <button
                              @click="closeBaggageRules"
                              type="button"
                              class="text-reset"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                              color="white"
                            >
                              <v-icon>mdi-close</v-icon>
                            </button>
                          </div>
                          <div class="offcanvas-body">
                            <v-card class="">
                              <v-tabs v-model="tab" align-tabs="center" stacked>
                                <v-tab value="tab-1" class="m-0">
                                  <v-icon>mdi-book-cancel-outline</v-icon>
                                  {{
                                    $t("baggageDetContent.contents.cancelRules")
                                  }}
                                </v-tab>
                                <v-tab value="tab-2">
                                  <v-icon>mdi-bag-checked</v-icon>
                                  {{ $t("baggageDetContent.contents.baggage") }}
                                </v-tab>
                              </v-tabs>

                              <v-card-text class="pt-0">
                                <keep-alive>
                                  <v-window v-model="tab">
                                    <div v-if="tab === 'tab-1'">
                                      <div class="p-2 cancellationRules">
                                        <div
                                          v-if="fareLoader"
                                          class="text-center"
                                          style="margin: auto"
                                        >
                                          {{
                                            $t(
                                              "baggageDetContent.contents.loaderText"
                                            )
                                          }}
                                          <v-progress-circular
                                            color="primary"
                                            class="ms-1"
                                            indeterminate
                                          ></v-progress-circular>
                                        </div>
                                        <div v-else>
                                          <h6 class="mt-2">
                                            {{
                                              $t(
                                                "baggageDetContent.contents.cancelRules"
                                              )
                                            }}:
                                          </h6>
                                          <div class="p-2 cancellationRules">
                                            <div
                                              v-html="fareRulesContent"
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div v-if="tab === 'tab-2'">
                                      <h6 class="mt-1">
                                        {{
                                          $t(
                                            "baggageDetContent.contents.baggageRules"
                                          )
                                        }}:
                                      </h6>
                                      <div
                                        class="p-2 rounded"
                                        v-for="(data, index) of roundresult"
                                        :key="index"
                                      >
                                        <div
                                          v-if="
                                            data.depBaggage && data.arrBaggage
                                          "
                                        >
                                          <div>
                                            <div
                                              style="
                                                font-size: 15px;
                                                font-weight: 500;
                                              "
                                            >
                                              {{
                                                data.Depature[0].Departure
                                                  .AirportCode
                                              }}
                                              -
                                              {{
                                                data.Depature[
                                                  data.Depature.length - 1
                                                ].Arrival.AirportCode
                                              }}
                                            </div>
                                            <div class="ms-2 mt-2">
                                              <div
                                                class="d-flex flex-row"
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 400;
                                                  margin-left: 5px;
                                                "
                                              >
                                                <span>
                                                  <v-icon
                                                    color="gray"
                                                    style="
                                                      color: gray !important;
                                                    "
                                                  >
                                                    mdi-bag-checked
                                                  </v-icon></span
                                                >
                                                <span
                                                  >{{
                                                    $t(
                                                      "baggageDetContent.contents.carryOn"
                                                    )
                                                  }}:</span
                                                >
                                                <span
                                                  v-if="data.depBaggage"
                                                  class="ms-1"
                                                >
                                                  {{
                                                    data.depBaggage
                                                      .PieceAllowance
                                                      .TotalQuantity > 0
                                                      ? 1 +
                                                        " " +
                                                        $t(
                                                          "baggageDetContent.contents.piece"
                                                        )
                                                      : 1 +
                                                        " " +
                                                        $t(
                                                          "baggageDetContent.contents.piece"
                                                        )
                                                  }}
                                                </span>
                                              </div>
                                            </div>
                                            <div class="ms-2 mt-2">
                                              <div
                                                class="d-flex flex-row"
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 400;
                                                  margin-left: 5px;
                                                "
                                              >
                                                <span>
                                                  <v-icon
                                                    color="gray"
                                                    style="
                                                      color: gray !important;
                                                    "
                                                  >
                                                    mdi-bag-suitcase
                                                  </v-icon></span
                                                >
                                                <span>
                                                  {{
                                                    $t(
                                                      "baggageDetContent.contents.checked"
                                                    )
                                                  }}:</span
                                                >
                                                <span
                                                  v-if="data.depBaggage"
                                                  class="ms-1"
                                                >
                                                  {{
                                                    data.depBaggage
                                                      .PieceAllowance
                                                      .TotalQuantity || 0
                                                  }}

                                                  {{
                                                    data.depBaggage
                                                      .PieceAllowance
                                                      .TotalQuantity > 1
                                                      ? $t(
                                                          "baggageDetContent.contents.pieces"
                                                        )
                                                      : $t(
                                                          "baggageDetContent.contents.piece"
                                                        )
                                                  }}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <hr v-if="data.Return" />
                                          <div v-if="data.Return" class="mt-2">
                                            <div
                                              class="mt-1"
                                              style="
                                                font-size: 15px;
                                                font-weight: 500;
                                              "
                                            >
                                              {{
                                                data.Depature[
                                                  data.Depature.length - 1
                                                ].Arrival.AirportCode
                                              }}
                                              -
                                              {{
                                                data.Depature[0].Departure
                                                  .AirportCode
                                              }}
                                            </div>

                                            <div class="ms-2 mt-2">
                                              <div
                                                class="d-flex flex-row"
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 400;
                                                  margin-left: 5px;
                                                "
                                              >
                                                <span>
                                                  <v-icon
                                                    color="gray"
                                                    style="
                                                      color: gray !important;
                                                    "
                                                  >
                                                    mdi-bag-checked
                                                  </v-icon></span
                                                >
                                                <span
                                                  >{{
                                                    $t(
                                                      "baggageDetContent.contents.carryOn"
                                                    )
                                                  }}:</span
                                                >
                                                <span class="ms-1">
                                                  {{
                                                    data.arrBaggage
                                                      .PieceAllowance
                                                      .TotalQuantity > 0
                                                      ? 1 +
                                                        " " +
                                                        $t(
                                                          "baggageDetContent.contents.piece"
                                                        )
                                                      : 1 +
                                                        " " +
                                                        $t(
                                                          "baggageDetContent.contents.piece"
                                                        )
                                                  }}
                                                </span>
                                              </div>
                                            </div>
                                            <div class="ms-2 mt-1">
                                              <div
                                                class="d-flex flex-row"
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 400;
                                                  margin-left: 5px;
                                                "
                                              >
                                                <span>
                                                  <v-icon
                                                    color="gray"
                                                    style="
                                                      color: gray !important;
                                                    "
                                                  >
                                                    mdi-bag-suitcase
                                                  </v-icon></span
                                                >
                                                <span>
                                                  {{
                                                    $t(
                                                      "baggageDetContent.contents.checked"
                                                    )
                                                  }}:</span
                                                >

                                                <span class="ms-1">
                                                  {{
                                                    data.arrBaggage
                                                      .PieceAllowance
                                                      .TotalQuantity || 0
                                                  }}
                                                  {{
                                                    data.arrBaggage
                                                      .PieceAllowance
                                                      .TotalQuantity > 1
                                                      ? $t(
                                                          "baggageDetContent.contents.pieces"
                                                        )
                                                      : $t(
                                                          "baggageDetContent.contents.piece"
                                                        )
                                                  }}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div v-else>
                                          {{
                                            $t(
                                              "baggageDetContent.contents.errText"
                                            )
                                          }}
                                        </div>
                                      </div>
                                      <div v-if="onewayresult">
                                        <div
                                          class="p-2 rounded"
                                          v-for="(data, index) of onewayresult"
                                          :key="index"
                                        >
                                          <div v-if="data.BaggageData">
                                            <div
                                              style="
                                                font-size: 15px;
                                                font-weight: 500;
                                              "
                                            >
                                              {{
                                                data.Depature[0].Departure
                                                  .AirportCode
                                              }}
                                              -
                                              {{
                                                data.Depature[
                                                  data.Depature.length - 1
                                                ].Arrival.AirportCode
                                              }}
                                            </div>
                                            <div class="ms-2 mt-2">
                                              <div
                                                class="d-flex flex-row"
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 400;
                                                  margin-left: 5px;
                                                "
                                              >
                                                <span>
                                                  <v-icon
                                                    color="gray"
                                                    style="
                                                      color: gray !important;
                                                    "
                                                  >
                                                    mdi-bag-checked
                                                  </v-icon></span
                                                >
                                                <span
                                                  >{{
                                                    $t(
                                                      "baggageDetContent.contents.carryOn"
                                                    )
                                                  }}:</span
                                                >
                                                <span
                                                  v-if="data.BaggageData"
                                                  class="ms-1"
                                                >
                                                  {{
                                                    data.BaggageData
                                                      .PieceAllowance
                                                      .TotalQuantity > 0
                                                      ? 1 +
                                                        " " +
                                                        $t(
                                                          "baggageDetContent.contents.piece"
                                                        )
                                                      : 1 +
                                                        " " +
                                                        $t(
                                                          "baggageDetContent.contents.piece"
                                                        )
                                                  }}
                                                </span>
                                              </div>
                                            </div>
                                            <div class="ms-2 mt-2">
                                              <div
                                                class="d-flex flex-row"
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 400;
                                                  margin-left: 5px;
                                                "
                                              >
                                                <span>
                                                  <v-icon
                                                    color="gray"
                                                    style="
                                                      color: gray !important;
                                                    "
                                                  >
                                                    mdi-bag-suitcase
                                                  </v-icon></span
                                                >
                                                <span>
                                                  {{
                                                    $t(
                                                      "baggageDetContent.contents.checked"
                                                    )
                                                  }}:</span
                                                >
                                                <span class="ms-1">
                                                  {{
                                                    data.BaggageData
                                                      .PieceAllowance
                                                      .TotalQuantity || 0
                                                  }}

                                                  {{
                                                    data.BaggageData
                                                      .PieceAllowance
                                                      .TotalQuantity > 1
                                                      ? $t(
                                                          "baggageDetContent.contents.pieces"
                                                        )
                                                      : $t(
                                                          "baggageDetContent.contents.piece"
                                                        )
                                                  }}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div v-else>
                                            {{
                                              $t(
                                                "baggageDetContent.contents.errText"
                                              )
                                            }}
                                          </div>
                                        </div>
                                      </div>
                                      <div v-if="multiresult.length > 0">
                                        <div
                                          class="p-2 rounded"
                                          v-for="(data, index) of multiresult"
                                          :key="index"
                                        >
                                          <div>
                                            <div
                                              style="
                                                font-size: 15px;
                                                font-weight: 500;
                                              "
                                            >
                                              {{
                                                data.Flight1[0].Departure
                                                  .AirportCode
                                              }}
                                              -
                                              {{
                                                data.Flight1[
                                                  data.Flight1.length - 1
                                                ].Arrival.AirportCode
                                              }}
                                            </div>
                                            <div class="ms-2 mt-2">
                                              <div
                                                class="d-flex flex-row"
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 400;
                                                  margin-left: 5px;
                                                "
                                              >
                                                <span>
                                                  <v-icon
                                                    color="gray"
                                                    style="
                                                      color: gray !important;
                                                    "
                                                  >
                                                    mdi-bag-checked
                                                  </v-icon></span
                                                >
                                                <span
                                                  >{{
                                                    $t(
                                                      "baggageDetContent.contents.carryOn"
                                                    )
                                                  }}:
                                                </span>
                                                <span class="">{{
                                                  data.BaggageData1
                                                    .PieceAllowance
                                                    .TotalQuantity > 0
                                                    ? 1 +
                                                      " " +
                                                      $t(
                                                        "baggageDetContent.contents.piece"
                                                      )
                                                    : 1 +
                                                      " " +
                                                      $t(
                                                        "baggageDetContent.contents.piece"
                                                      )
                                                }}</span>
                                              </div>
                                            </div>
                                            <div class="ms-2 mt-2">
                                              <div
                                                class="d-flex flex-row"
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 400;
                                                  margin-left: 5px;
                                                "
                                              >
                                                <span>
                                                  <v-icon
                                                    color="gray"
                                                    style="
                                                      color: gray !important;
                                                    "
                                                  >
                                                    mdi-bag-suitcase
                                                  </v-icon></span
                                                >
                                                <span>
                                                  {{
                                                    $t(
                                                      "baggageDetContent.contents.checked"
                                                    )
                                                  }}:
                                                </span>
                                                <span class="">
                                                  {{
                                                    data.BaggageData1
                                                      .PieceAllowance
                                                      .TotalQuantity || 0
                                                  }}{{
                                                    data.BaggageData1
                                                      .PieceAllowance
                                                      .TotalQuantity > 1
                                                      ? $t(
                                                          "baggageDetContent.contents.pieces"
                                                        )
                                                      : $t(
                                                          "baggageDetContent.contents.piece"
                                                        )
                                                  }}</span
                                                >
                                              </div>
                                            </div>

                                            <div class="ms-2 mt-2"></div>
                                          </div>
                                          <hr v-if="data.Flight2" />
                                          <div v-if="data.Flight2" class="mt-2">
                                            <div
                                              class="mt-1"
                                              style="
                                                font-size: 15px;
                                                font-weight: 500;
                                              "
                                            >
                                              {{
                                                data.Flight2[0].Departure
                                                  .AirportCode
                                              }}
                                              -
                                              {{
                                                data.Flight2[
                                                  data.Flight2.length - 1
                                                ].Arrival.AirportCode
                                              }}
                                            </div>
                                            <div class="ms-2 mt-2">
                                              <div
                                                class="d-flex flex-row"
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 400;
                                                  margin-left: 5px;
                                                "
                                              >
                                                <span>
                                                  <v-icon
                                                    color="gray"
                                                    style="
                                                      color: gray !important;
                                                    "
                                                  >
                                                    mdi-bag-checked
                                                  </v-icon></span
                                                >
                                                <span
                                                  >{{
                                                    $t(
                                                      "baggageDetContent.contents.carryOn"
                                                    )
                                                  }}:
                                                </span>
                                                <span class="">{{
                                                  data.BaggageData2
                                                    .PieceAllowance
                                                    .TotalQuantity > 0
                                                    ? 1 +
                                                      " " +
                                                      $t(
                                                        "baggageDetContent.contents.piece"
                                                      )
                                                    : 1 +
                                                      " " +
                                                      $t(
                                                        "baggageDetContent.contents.piece"
                                                      )
                                                }}</span>
                                              </div>
                                            </div>
                                            <div class="ms-2 mt-2">
                                              <div
                                                class="d-flex flex-row"
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 400;
                                                  margin-left: 5px;
                                                "
                                              >
                                                <span>
                                                  <v-icon
                                                    color="gray"
                                                    style="
                                                      color: gray !important;
                                                    "
                                                  >
                                                    mdi-bag-suitcase
                                                  </v-icon></span
                                                >
                                                <span>
                                                  {{
                                                    $t(
                                                      "baggageDetContent.contents.checked"
                                                    )
                                                  }}:
                                                </span>
                                                <span class="">
                                                  {{
                                                    data.BaggageData2
                                                      .PieceAllowance
                                                      .TotalQuantity || 0
                                                  }}{{
                                                    data.BaggageData2
                                                      .PieceAllowance
                                                      .TotalQuantity > 1
                                                      ? $t(
                                                          "baggageDetContent.contents.pieces"
                                                        )
                                                      : $t(
                                                          "baggageDetContent.contents.piece"
                                                        )
                                                  }}</span
                                                >
                                              </div>
                                            </div>
                                          </div>
                                          <hr v-if="data.Flight3" />
                                          <div v-if="data.Flight3" class="mt-2">
                                            <div
                                              class="mt-1"
                                              style="
                                                font-size: 15px;
                                                font-weight: 500;
                                              "
                                            >
                                              {{
                                                data.Flight3[0].Departure
                                                  .AirportCode
                                              }}
                                              -
                                              {{
                                                data.Flight3[
                                                  data.Flight3.length - 1
                                                ].Arrival.AirportCode
                                              }}
                                            </div>
                                            <div class="ms-2 mt-2">
                                              <div
                                                class="d-flex flex-row"
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 400;
                                                  margin-left: 5px;
                                                "
                                              >
                                                <span>
                                                  <v-icon
                                                    color="gray"
                                                    style="
                                                      color: gray !important;
                                                    "
                                                  >
                                                    mdi-bag-checked
                                                  </v-icon></span
                                                >
                                                <span
                                                  >{{
                                                    $t(
                                                      "baggageDetContent.contents.carryOn"
                                                    )
                                                  }}:
                                                </span>
                                                <span class="">{{
                                                  data.BaggageData3
                                                    .PieceAllowance
                                                    .TotalQuantity > 0
                                                    ? 1 +
                                                      " " +
                                                      $t(
                                                        "baggageDetContent.contents.piece"
                                                      )
                                                    : 1 +
                                                      " " +
                                                      $t(
                                                        "baggageDetContent.contents.piece"
                                                      )
                                                }}</span>
                                              </div>
                                            </div>
                                            <div class="ms-2 mt-2">
                                              <div
                                                class="d-flex flex-row"
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 400;
                                                  margin-left: 5px;
                                                "
                                              >
                                                <span>
                                                  <v-icon
                                                    color="gray"
                                                    style="
                                                      color: gray !important;
                                                    "
                                                  >
                                                    mdi-bag-suitcase
                                                  </v-icon></span
                                                >
                                                <span>
                                                  {{
                                                    $t(
                                                      "baggageDetContent.contents.checked"
                                                    )
                                                  }}:
                                                </span>
                                                <span class="">
                                                  {{
                                                    data.BaggageData3
                                                      .PieceAllowance
                                                      .TotalQuantity || 0
                                                  }}{{
                                                    data.BaggageData3
                                                      .PieceAllowance
                                                      .TotalQuantity > 1
                                                      ? $t(
                                                          "baggageDetContent.contents.pieces"
                                                        )
                                                      : $t(
                                                          "baggageDetContent.contents.piece"
                                                        )
                                                  }}</span
                                                >
                                              </div>
                                            </div>
                                          </div>
                                          <hr v-if="data.Flight4" />
                                          <div v-if="data.Flight4" class="mt-2">
                                            <div
                                              class="mt-1"
                                              style="
                                                font-size: 15px;
                                                font-weight: 500;
                                              "
                                            >
                                              {{
                                                data.Flight4[0].Departure
                                                  .AirportCode
                                              }}
                                              -
                                              {{
                                                data.Flight4[
                                                  data.Flight4.length - 1
                                                ].Arrival.AirportCode
                                              }}
                                            </div>
                                            <div class="ms-2 mt-2">
                                              <div
                                                class="d-flex flex-row"
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 400;
                                                  margin-left: 5px;
                                                "
                                              >
                                                <span>
                                                  <v-icon
                                                    color="gray"
                                                    style="
                                                      color: gray !important;
                                                    "
                                                  >
                                                    mdi-bag-checked
                                                  </v-icon></span
                                                >
                                                <span
                                                  >{{
                                                    $t(
                                                      "baggageDetContent.contents.carryOn"
                                                    )
                                                  }}:
                                                </span>
                                                <span class="">{{
                                                  data.BaggageData4
                                                    .PieceAllowance
                                                    .TotalQuantity > 0
                                                    ? 1 +
                                                      " " +
                                                      $t(
                                                        "baggageDetContent.contents.piece"
                                                      )
                                                    : 1 +
                                                      " " +
                                                      $t(
                                                        "baggageDetContent.contents.piece"
                                                      )
                                                }}</span>
                                              </div>
                                            </div>
                                            <div class="d-flex ms-2 mt-2">
                                              <div
                                                class="d-flex flex-row"
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 400;
                                                  margin-left: 5px;
                                                "
                                              >
                                                <span
                                                  ><v-icon
                                                    color="gray"
                                                    style="
                                                      color: gray !important;
                                                    "
                                                  >
                                                    mdi-bag-suitcase
                                                  </v-icon></span
                                                >
                                                <span>
                                                  {{
                                                    $t(
                                                      "baggageDetContent.contents.checked"
                                                    )
                                                  }}:
                                                </span>
                                                <span class="">
                                                  {{
                                                    data.BaggageData4
                                                      .PieceAllowance
                                                      .TotalQuantity || 0
                                                  }}{{
                                                    data.BaggageData4
                                                      .PieceAllowance
                                                      .TotalQuantity > 1
                                                      ? $t(
                                                          "baggageDetContent.contents.pieces"
                                                        )
                                                      : $t(
                                                          "baggageDetContent.contents.piece"
                                                        )
                                                  }}</span
                                                >
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </v-window>
                                </keep-alive>
                              </v-card-text>
                            </v-card>
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                  <hr class="mt-1 mb-2" />

                  <!-- <br /> -->
                  <p style="font-size: 12px">
                    {{ $t("fareDetContent.priceInfo1") }} {{ metaCurrency }}.
                    {{ $t("fareDetContent.priceInfo2") }}
                  </p>
                </div>

                <!-- <div>
                 <button class="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop" @click="getFareRules()" aria-controls="staticBackdrop">
                  Baggage and Cancellation Rules
                </button>
                </div> -->
              </div>
            </v-card>

            <!-- <div class="mt-3 mb-3" align="center">
              <v-btn width="80%" height="40px" type="submit" style="
                  font-size: 16px;
                  font-weight: 500;
                  color: white;
                  text-transform: capitalize;
                  background-color: #162849;
                ">
                Continue</v-btn>
            </div> -->
          </div>
        </div>
  </div>
</div>

 

  <div v-if="pageloader">
    <pageLoader></pageLoader>
  </div>

  <v-dialog v-model="seatmapError" persistent>
    <div class="d-flex justify-center">
      <v-card class="p-3" style="max-width: 450px;">
        <!-- <h3 class="text-center">{{ $t("errorContent.dialog1.text1") }}</h3> -->
        <h5 class="text-center">Sorry, the seat selection is unavailable. </h5>
        <h6 class="text-center">Please proceed without seat selection, and an automatic seat will be assigned to you. Thank you!</h6>
        <div class="d-flex justify-space-evenly mt-3">
          <v-btn @click="continuePayment()" color="#26225f">Continue to Payment</v-btn>
        </div>
      </v-card>
    </div>
  </v-dialog>

  <v-dialog v-model="dialog" max-width="500">
  <v-card>
    <!-- <v-card-title class="text-center">Confirmation</v-card-title> -->
    <v-card-text>Please select all seats before continuing.To proceed to the next Flight.</v-card-text>
    <v-card-actions>
      <!-- <v-btn text @click="cancelSelection" style="background-color:#c62a82;color:white;">Cancel</v-btn> -->
      <v-btn text @click="confirmSelection" style="background-color:#26225f;color:white;">OK</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<v-dialog v-model="dialog2" persistent>
  <div class="d-flex justify-center">
    <v-card class="p-3" style="max-width: 450px;">
      <h5 class="text-center">Seat Selection for this Flight is not available. </h5>
      <h6 class="text-center">Please Contact Cutomer Care after completing the Booking.</h6>
        <div class="d-flex justify-space-evenly mt-3">
          <v-btn @click="dialog2 = false" style="background-color:#26225f;color:white;">OK</v-btn>
        </div>
    </v-card>
  </div>
</v-dialog>

</template>

<script>
import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";
import axios from "axios";
import pageLoader from "@/components/loaderComponent.vue";
import moment from "moment";
export default{
    components: {
    pageLoader,
  },
    data(){
    return{
      portal_id:"",
      portal_domain:"",
      seatmapApi:"",
      pageloader:true,
      totalSeat: [],
      totalData: [], 
      totalpassanger:[], 
      seatSelect:[],
      passSelect:0,
      flightSelect:0,
      flightSelectionStatus: {},
      seatCharge:false,
      singlePass:false,
      indexData: 0,
      passengerCount:"",
      local_id :"",
      getTotalData:"",
      routerData:"",
      seatmapError:false,
      dialog2:false,
      getroutequery:"",
      pointofsale:"",
      topSticky: "10%",
      totalSeatPrice:0.00,
      selectedFlights : {},
      selectedFlights1 : {},
      seatCurrency:"",
      totalAmountpassenger: [],
      currency: "",
      totalTaxPassanger: [],
      totalbasePassanger: [],
      metaCurrency: "",
      displayCurrency: "",
      onewayresult: [],
      roundresult: [],
      multiresult: [],
      paxcontact: [],
      adult: "",
      child: "",
      inf: "",
      fareRulesApi:"",
      fareRulesContent: "",
      tab: null,
      dataloader: true,
      adultLength: "",
      childLength: "",
      infantLength: "",
      adultDetailData: [],
      childDetailData: [],
      infDetailData: [],
      totalPassData: [],
      passDeatail: [],
      dialog:false,
      nextFlightCallback: null,
      selectId:"",
      shoppingid:""
    }
    
},
unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
methods: {
  continuePayment(){
    let locales_id = this.local_id || this.Localoneway_id
    this.$router.push({
        path: "/confirmBook",
        query: { [this.routerData]: locales_id }
      });
  },
  confirmSelection() {
      if (this.nextFlightCallback) {
        this.nextFlightCallback();
      }
      this.dialog = false;
    },
    cancelSelection() {
      this.dialog = false;
    },
  getperpersonPrice(getdata) {
      if (getdata.adultcount) {
        let getAdtperfare =
          parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
        console.log(getAdtperfare, "getadultTotBasegetadultTotBase...2");
        return getAdtperfare.toFixed(2);
      }

      if (getdata.childcount) {
        let getChdperfare =
          parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
        console.log(getChdperfare, "getadultTotBasegetadultTotBase...3");
        return getChdperfare.toFixed(2);
      }

      if (getdata.infcount) {
        let getInfperfare =
          parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
        console.log(getInfperfare, "getadultTotBasegetadultTotBase...4");
        return getInfperfare.toFixed(2);
      }
    },
    passengerDetail() {
      this.passDeatail = JSON.parse(sessionStorage.getItem("TravelerDetails"));
      console.log(this.adult, "this.adult this.adult ");

      for (let i = 0; i < this.passDeatail.adultDatas.length; i++) {
        if (i < this.adult) {
          this.adultDetailData.push({
            lastname: "",
            firstname: "",
            middlename: null,
            salutation: "",
            gender: "",
            dob: "",
            pax_type: "Adult",
            seat_requested:[]
          });
        }
      }

      this.adultDetailData.forEach((a, i) => {
        this.passDeatail.adultDatas.forEach((v, j) => {
          if (i == j) {
            a.lastname = v.last;
            a.firstname = v.fname;
            a.middlename = v.middle;
            a.salutation = v.salutation;
            a.gender = v.gender;
            a.dob = moment(v.dateOfBirth).format("DD-MMM-YYYY");
            a.seat_requested = v.seat_requested;
          }
        });
      });

      // console.log(this.adultDetailData,'this.adultDetailDatathis.adultDetailData')

      for (let i = 0; i < this.passDeatail.childDatas.length; i++) {
        if (i < this.child) {
          this.childDetailData.push({
            lastname: "",
            firstname: "",
            middlename: null,
            salutation: "",
            gender: "",
            dob: "",
            pax_type: "Child",
            seat_requested:[]
          });
        }
      }

      this.childDetailData.forEach((a, i) => {
        this.passDeatail.childDatas.forEach((v, j) => {
          if (i == j) {
            a.lastname = v.last;
            a.firstname = v.fname;
            a.middlename = v.middle;
            a.salutation = v.salutation;
            a.gender = v.gender;
            a.dob = moment(v.dateOfBirth).format("DD-MMM-YYYY");
            a.seat_requested = v.seat_requested;
          }
        });
      });

      for (let i = 0; i < this.passDeatail.infrantDatas.length; i++) {
        if (i < this.inf) {
          this.infDetailData.push({
            lastname: "",
            firstname: "",
            middlename: null,
            salutation: "",
            gender: "",
            dob: "",
            pax_type: "Infant",
            seat_requested:[]
          });
        }
      }

      this.infDetailData.forEach((a, i) => {
        this.passDeatail.infrantDatas.forEach((v, j) => {
          if (i == j) {
            a.lastname = v.last;
            a.firstname = v.fname;
            a.middlename = v.middle;
            a.salutation = v.salutation;
            a.gender = v.gender;
            a.dob = moment(v.dateOfBirth).format("DD-MMM-YYYY");
            a.seat_requested = v.seat_requested;
          }
        });
      });

      this.totalPassData = [
        ...this.adultDetailData,
        ...this.childDetailData,
        ...this.infDetailData,
      ];

      this.totalPassData.forEach((c) => {
        c.fareup = true;
      });

      console.log(this.totalPassData, "this.totalPassDatathis.totalPassData");
    },
    closeBaggageRules() {
      this.tab = "tab-1";
      // document.documentElement.style.overflow = 'scroll';
      document.body.style.overflow = "auto";
      document.body.style.position = "static";
    },
    async getFareRules() {
      window.scrollTo(0, 0);

      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.width = "100%";

      let fareRequest = {
        request_type: "farerules",
        shopping_response_id: this.shoppingid,
        offer_id: this.selectId,
        portal_id: this.portal_id,
        portal_domain: this.portal_domain
      };
      console.log(fareRequest, "fareRequestssssss");
      await axios
        .get(this.fareRulesApi, {
          headers: {
            "Content-Type": "text/html",
          },
          params: fareRequest,
        })
        .then((response) => {
          this.fareRulesContent = response.data;
          // console.log(response.data, "fareRulesContentfareRulesContent.....")
        })

        .catch((error) => {
          console.log(error, "erroroor.....");
        });
    },

  getConfig() {
    const getConfigData1 = getConfigDataFromLocalStorage();
    if (getConfigData1) {  
      this.seatmapApi = getConfigData1.payload.portal_configuration.API_endpoints.seatmap;
      console.log(this.seatmapApi,'this.seatmapApi');
        this.displayCurrency = getConfigData1.payload.portal_configuration.currency.display;
        this.metaCurrency = getConfigData1.payload.portal_configuration.currency.iso_code;
        this.portal_id = getConfigData1.payload.portal_configuration.portal_id;
        this.portal_domain = getConfigData1.payload.portal_configuration.portal_url.replace("https://","");
        this.pointScale = getConfigData1.payload.portal_configuration.pointofsale;
        this.airLogoApi = getConfigData1.payload.portal_configuration.content_data.airlines_logo;
        this.fareRulesApi = getConfigData1.payload.portal_configuration.API_endpoints.farerules;
    }
  }, 

  async  getseatMapping() {
    this.dataLoder=true
      let seat = {
        request_type: "seatmap",
        offer_id: "",
        shopping_response_id: "",
        hit_id: "",
        search_cabin_type: "",
        trip_type: "",
        pointofsale:"",
        adult: "",
        child: "",
        paxInfant: "",
      };
    console.log(this.getTotalData,'this.getTotalData')
    
      seat.shopping_response_id =this.getTotalData.ShoppingResponseId  ;
      seat.offer_id =this.getTotalData.selectId;
      seat.hit_id =this.getTotalData.hitId;
      seat.search_cabin_type =this.getTotalData.cabinClass;
      seat.pointofsale =this.pointScale;
      seat.adult =this.getTotalData.Adult;
      seat.child =this.getTotalData.Child || 0;
      seat.paxInfant =this.getTotalData.Inf || 0;
      seat.trip_type =this.getTotalData.triptype;

      let obj = {
        adult: this.getTotalData.Adult,
        chd: this.getTotalData.Child || 0,
        inf: this.getTotalData.Inf || 0
      }

      console.log(seat,this.seatmapApi,'4567')

    await axios.post(this.seatmapApi, seat, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((responce) => {
      // console.log(responce,this.seatSelect, "seatmapdata223");
      this.seatSelect = responce.data.data;
      console.log(this.seatSelect,'this.seatSelect')
      this.checkSeatmap(this.seatSelect);
      this.getseatselect();
      this.seatPassanger(obj);
      this.fetchTravelerData();
      this.pageloader=false;
    })
    .catch((err) => {
      this.pageloader=false;
      console.log(err, "ppppp52222");
      this.seatmapError = true;
    });

      // console.log(seat, "kkkk3333333333");
  },
  checkSeatmap(flightData) {
    const allNoCabins = flightData.every(segment => 
      segment.every(flight => !flight.cabins || flight.cabins.length === 0)
    );
    console.log(allNoCabins,'allNoCabinsallNoCabins')

    this.seatmapError = allNoCabins;
  },

getseatselect() {
  let $event = [];
  this.seatSelect.forEach((v, i) => {
    // console.log(v, i, "keerthikannan123");
    this.seatSelect[i].forEach((c) => {
      // console.log(c,c.cabins.length, "vasanthaganesh");
      $event.push(c);
//           if (c && Array.isArray(c.cabins) && c.cabins.length > 0) {
//   $event.push(c);
// }

    });
  });
  this.totalSeat = $event;

  if (this.totalSeat.length > 0) {
   if(this.totalSeat[0].cabins && this.totalSeat[0].cabins.length > 0){
    this.totalSeat[0].cabins.forEach((c) => {
      // console.log(c, "ppppppppppppppppp");
      let arrayData = [];
      arrayData = c.columns;


      let data = 0;
      arrayData.forEach((a, l) => {
        console.log(a, "rowsrows");
        if (a.type == "AisleA") {
          data = data + 1;
          if (data == 2) {
            arrayData[l - 1].space = true;
            arrayData[l].space = false;
          }
        } else {
          data = 0;
          arrayData[l].space = false;
        }
        c.seat_rows.forEach((d) => {
          console.log(d,a,'000000')
          if (d.seats[0].seat_number == a.column) {
            console.log(this.totalData , d,a.column,'lololol')

            if (!this.totalData[a.column]) {
              this.totalData[a.column] = [];
            }

            this.totalData[a.column].push(d);
          }
        });
        this.totalData[a.column].forEach((w) => {
          w.color = false;
          console.log(w, "dddwwwwdddd");
        });
       
      });
      console.log(arrayData, "arrayData");
    });
   
    console.log(this.totalData, "$data$data$data");
    this.pageloader=false
  }
  else{
    // this.dialog2 = true;
  }
  }
},
checkFlightSegment() {
  let anyPassengerWithoutSeat = this.totalpassanger.some((passenger) => {
    let seatNumber = passenger.seat_number;
    console.log(passenger,'passengerrr')

    if (!Array.isArray(seatNumber)) {
      seatNumber = seatNumber ? [seatNumber] : [];
    }
    if(passenger.type == "Infrant"){
      return false;
    }

    if (!this.totalSeat[this.indexData] || 
        !this.totalSeat[this.indexData].cabins || 
        !this.totalSeat[this.indexData].cabins[0] || 
        !this.totalSeat[this.indexData].cabins[0].columns) {
      console.log("Cabins or columns missing, returning false...");
      return true;
    }

    let hasFlightSelect = seatNumber.some((_, index) => index === this.flightSelect);

    console.log(seatNumber, this.flightSelect, hasFlightSelect, "Checking passenger...");

    return !hasFlightSelect;
  });

  if (anyPassengerWithoutSeat) {
    console.log("Not all passengers have selected seats. Displaying v-dialog...");
    return false;
  }

  console.log("All passengers have selected seats. Moving to the next flight...");
  return true;
},

 finalizeSeats(flightKey) {
    this.flightSelectionStatus[flightKey] = true;
    console.log(`Seat selection finalized for Flight ${flightKey}. No further changes allowed.`);
    // this.totalAmountpassenger = parseFloat(Number(this.totalAmountpassenger) + Number(this.totalSeatPrice)).toFixed(2);
    // console.log(this.totalAmountpassenger,this.totalSeatPrice,'totalAmounttt')
    // this.seatCharge = true;
  },

selectdata(data, index) {
  console.log(data, index,'000000');
    // console.log(data.cabins, data.cabins.length === 0,'000000...1');
  if (!data || !data.cabins || data.cabins.length === 0) {
    this.dialog2 = true;
    this.flightSelect=index
    this.passSelect = 0;
    this.indexData = index;
    this.singlePass = false;
    return
  }

   if (this.flightSelectionStatus && this.flightSelectionStatus[index]) {
    console.log(`Flight ${index} is already selected.`);
    this.flightSelect = index;
    this.indexData = index;
    this.passSelect = this.passengerCount
  // this.singlePass = false;
  this.totalData = [];
  let falData = false;
  if (this.totalSeat.length > 0) {
    this.totalSeat[index].cabins.forEach((c, k) => {
      c.seat_rows[k].color = falData[k];

      let arrayData = [];
      arrayData = c.columns;
      let data = 0;
      arrayData.forEach((a, l) => {
        if (a.type == "AisleA") {
          data = data + 1;
          if (data == 2) {
            arrayData[l - 1].space = true;
            arrayData[l].space = false;
          }
        } else {
          data = 0;
          arrayData[l].space = false;
        }

        c.seat_rows.forEach((d) => {
          // console.log(d, "rowsrows");
          if (d.seats[d.seats.length-1].seat_number == a.column) {
            if (!this.totalData[a.column]) {
              this.totalData[a.column] = [];
            }
            this.totalData[a.column].push(d);
          }
        });

        // this.totalData[a.column].forEach((w) => {
        //   console.log(w, "dddwwwwdddd");
        // });
      });
    });
  }
   this.handleSeatmap()
  }
  else {
    let data = this.checkFlightSegment()
    if(!data && this.totalSeat[this.indexData] && this.totalSeat[this.indexData].cabins 
    && this.totalSeat[this.indexData].cabins.length > 0){
      console.log(data,'dataasss')
      this.dialog = true
    }
  else {
    this.flightSelect=index
  this.passSelect = 0;
  this.indexData = index;
  this.singlePass = false;
  this.totalData = [];
  let falData = false;
  if (this.totalSeat.length > 0) {
    this.totalSeat[index].cabins.forEach((c, k) => {
      c.seat_rows[k].color = falData[k];

      let arrayData = [];
      arrayData = c.columns;
      let data = 0;
      arrayData.forEach((a, l) => {
        if (a.type == "AisleA") {
          data = data + 1;
          if (data == 2) {
            arrayData[l - 1].space = true;
            arrayData[l].space = false;
          }
        } else {
          data = 0;
          arrayData[l].space = false;
        }

        c.seat_rows.forEach((d) => {
          // console.log(d, "rowsrows");
          if (d.seats[d.seats.length-1].seat_number == a.column) {
            if (!this.totalData[a.column]) {
              this.totalData[a.column] = [];
            }
            this.totalData[a.column].push(d);
          }
        });

        // this.totalData[a.column].forEach((w) => {
        //   console.log(w, "dddwwwwdddd");
        // });
      });
    });
  }
   this.handleSeatmap()
  }
  }
},

  seatPassanger(obj) {
    const totalPassengers = Number(obj.adult || 0) + Number(obj.chd || 0) + Number(obj.inf || 0);
    console.log(totalPassengers, 'totalPassengers')
    this.totalpassanger = [];

    for (let i = 0; i < Number(obj.adult || 0); i++) {
      this.totalpassanger.push({ seat_number: [], type: 'Adult', index: i, name:''});
    }

    for (let i = 0; i < Number(obj.chd || 0); i++) {
      this.totalpassanger.push({ seat_number: [], type: 'Child', index: i, name:'' });
    }

    for (let i = 0; i < Number(obj.inf || 0); i++) {
      this.totalpassanger.push({ type: 'Infrant', index: i, name:'' });
        // index++;
    }


    console.log(this.totalpassanger, this.totalSeat.length, "this.totalpassanger");
  },

  totalSeatData() {
    let travelerData = JSON.parse(sessionStorage.getItem('TravelerDetails')) || {
      adultDatas: [],
      childDatas: [],
      infrantDatas: []
    };

    console.log(travelerData, 'Initial travelerData');

    this.totalpassanger.forEach((passenger) => {
      console.log(passenger, 'Processing passenger');

      if (passenger.type === "Adult") {
        if (!travelerData.adultDatas[passenger.index]) {
          console.warn(`No adult data at index ${passenger.index}`);
          return;
        }
        if (!travelerData.adultDatas[passenger.index].seat_requested) {
          travelerData.adultDatas[passenger.index].seat_requested = [];
        }
        travelerData.adultDatas[passenger.index].seat_requested = passenger.seat_number;
      } else if (passenger.type === "Child") {
        if (!travelerData.childDatas[passenger.index]) {
          console.warn(`No child data at index ${passenger.index}`);
          return;
        }
        if (!travelerData.childDatas[passenger.index].seat_requested) {
          travelerData.childDatas[passenger.index].seat_requested = [];
        }
        travelerData.childDatas[passenger.index].seat_requested = passenger.seat_number;
      } else {
        console.warn(`Unknown passenger type: ${passenger.type}`);
      }
    });

    sessionStorage.setItem('TravelerDetails', JSON.stringify(travelerData));

    console.log(travelerData, 'Updated travelerData saved to localStorage');
  },

seatSelectdata(datas, index1, columnIndex) {
  console.log(datas, index1, columnIndex, 'passengerData');
  console.log(this.adult, this.child, this.inf, 'this.adultthis.adult');

  this.passengerCount = Number(this.adult) + Number(this.child);
  
  if (this.passSelect >= this.passengerCount) {
    console.log("Seat selection limit reached for this flight. Please select another flight manually.");
    return;
  }

  if (datas.seats[0].occupied === "true") {
    console.log("Seat is already occupied. Select a different seat.");
    return;
  }

  console.log(this.passSelect, this.flightSelect, this.flightSelectionStatus, this.totalpassanger, 'passengerData...1');
  const passenger = this.totalpassanger[this.passSelect];

  if ((passenger && this.singlePass) || this.passengerCount === 1) {
    this.handleSinglePassengerSelection(datas, index1, columnIndex, passenger);
  } else if (this.totalData[columnIndex]?.[index1] && !this.flightSelectionStatus[this.flightSelect] && !this.singlePass) {
    this.handleMultiPassengerSelection(datas, index1, columnIndex);
  } else {
    console.log("Invalid seat selection. Please check the indices.");
  }
},

handleSinglePassengerSelection(datas, index1, columnIndex, passenger) {
  console.log("Passenger Data:", passenger, this.singlePass);

  if (passenger) {
    console.log("Single Passenger Mode Active");

    if (this.passengerCount === 1) {
      this.passSelect = 0;
    }

    let seatNumber = Array.isArray(passenger?.seat_number) ? passenger.seat_number : [];

    if (!Array.isArray(seatNumber) && passenger.seat_number) {
      seatNumber = [...passenger.seat_number];
    }

    this.resetPreviousSeat(seatNumber);

    const selectedSeat = this.totalData[columnIndex]?.[index1];
    if (selectedSeat) {
      if (selectedSeat.selectedPass) {
        console.log("Seat is already selected by another passenger.");
        return;
      }

      this.updateSeatSelection(datas, selectedSeat);
      passenger.seat_number[this.flightSelect] = this.formatSeatDetails(datas, columnIndex);
      this.updateSeatPrice(datas);
      this.finalizeSeats(this.flightSelect);

      console.log(`Passenger ${this.passSelect + 1} selected seat ${datas.row_number}${columnIndex}.`, this.totalpassanger);
    } else {
      console.log("Invalid seat selection. Please check the indices.");
    }
  }
},

handleMultiPassengerSelection(datas, index1, columnIndex) {
  console.log('passengercolorTrueConditonnnnn');

  const selectedSeat = this.totalData[columnIndex][index1];
  selectedSeat.color = true;
  selectedSeat.selectedPass = this.passSelect + 1;

  this.totalpassanger[this.passSelect].seat_number[this.flightSelect] = this.formatSeatDetails(datas, columnIndex);

  this.updateSeatPrice(datas);

  if (this.passSelect < this.passengerCount && !this.singlePass) {
    this.passSelect++;
  }

  if (this.passSelect >= this.passengerCount) {
    this.finalizeSeats(this.flightSelect);
  }
},

resetPreviousSeat(seatNumber) {
  if (seatNumber.length > 0) {
    const firstSeat = seatNumber[this.flightSelect];
    console.log("First Seat Number:", firstSeat);
    if (firstSeat) {
      let { row, col } = this.extractSeatPosition(firstSeat);
      if (row !== undefined && col !== undefined) {
        col = col.replace(/"/g, '');
        const previousSeat = this.totalData[col];
        previousSeat.forEach(i => {
          if (i.row_number == Number(row)) {
            i.color = false;
            i.selectedPass = null;
            console.log(i, "First Seat Datasss");
          }
        });
      }
    }
  }
},

updateSeatSelection(datas, selectedSeat) {
  selectedSeat.color = true;
  selectedSeat.selectedPass = this.passSelect + 1;
},

formatSeatDetails(datas, columnIndex) {
  return `${this.totalSeat[this.flightSelect].origin}-${this.totalSeat[this.flightSelect].destination}:${datas.row_number}${columnIndex}`;
},

updateSeatPrice(datas) {
  let passengerId = this.passSelect;
  let flightId = this.flightSelect;
  let seatPrice = Number(datas.seats[0].price);

  if (!this.selectedFlights1[passengerId]) {
    this.selectedFlights1[passengerId] = {};
  }

  this.selectedFlights1[passengerId][flightId] = (seatPrice === 0 || datas.seats[0].price === '-') ? 0.00 : seatPrice;

  this.totalSeatPrice = Object.values(this.selectedFlights1)
    .flatMap(flightObj => Object.values(flightObj))
    .reduce((acc, price) => acc + price, 0);

  console.log(this.totalSeatPrice, this.selectedFlights1, datas.seats[0].price, 'totalSeatPrice...2');
},

extractSeatPosition(seatString) {
  const parts = seatString.split(":");
  const rowCol = parts[1];
  const row = rowCol.slice(0, -1);
  const col = rowCol.slice(-1);
  console.log(seatString,parts,rowCol,'seatStringggg',row,col)
  return {
    row: parseInt(row, 10),
    col: col
  };
},

  handleSeatmap(){
     this.totalpassanger.forEach(passenger => {
      console.log(passenger,'updateSeatMap..41')
    if (passenger.seat_number && passenger.seat_number[this.flightSelect]) {
      let seat= passenger.seat_number[this.flightSelect]
        if (seat) {
          const seatDetails = seat.split(':')[1]; // Extract the seat details before the colon
          const match = seatDetails.match(/^(\d+)([A-Za-z]+)$/);

            console.log(seat,match,seatDetails,seat.split(':'), "updateSeatMap...1");
          if (match) {
            const row = match[1]; // Extract the row (digits)
            const column = match[2]; // Extract the column (letter)
            this.updateSeatMap(row, column, this.flightSelect, passenger);
          } else {
            console.warn(`Invalid seat format: ${seatDetails}`); // Log invalid seat format
          }
        }
    }
  });
  },
  updateSeatMap(row, column, flightIndex, passenger) {
    console.log(row, column, flightIndex, passenger,this.totalData,'updateSeatMap')
    if (this.totalData[column]) {
      console.log(this.totalData,this.totalData[column],'updateSeatMap..2')
      this.totalData[column].forEach((data) => {
        if(data.row_number == row){
          console.log(data.row_number,row,"updateSeatMap..3")
          data.color = true;
          data.selectedPass = this.passSelect + 1;
            this.passSelect++
        }
      })
    }
  },

totalpassData(data, index) {
  console.log(data, index, "data, index");
  let tempPass = this.passSelect;
  console.log(tempPass,'passengerdataa--Temppp')
  this.passSelect = index;
  this.singlePass = true;
},

  fetchTravelerData() {
    const travelerData = JSON.parse(sessionStorage.getItem('TravelerDetails')) || {
      adultDatas: [],
      childDatas: [],
      infrantDatas: []
    };

    this.totalpassanger = travelerData.adultDatas.map((adult, index) => ({
      type: "Adult",
      index,
      name: `${adult.fname} ${adult.last || ''}`.trim(),
      seat_number: adult.seat_requested || []
    }));

    this.totalpassanger = this.totalpassanger.concat(
      travelerData.childDatas.map((child, index) => ({
        type: "Child",
        index,
        name: `${child.fname} ${child.last || ''}`.trim(),
        seat_number: child.seat_requested || []
      }))
    );

    this.totalpassanger = this.totalpassanger.concat(
      travelerData.infrantDatas.map((infant, index) => ({
        type: "Infrant",
        index,
        name: `${infant.fname} ${infant.last || ''}`.trim(),
      }))
    );

    console.log(this.totalpassanger, 'Processed totalpassanger');
     this.handleSeatmap()
  },
  onContinue() {
  if (this.validateSeatSelection()) {
    this.totalSeatData();
    console.log("All seats selected. Proceeding to the next page.");
    let locales_id = this.local_id || this.Localoneway_id
    this.$router.push({
        path: "/confirmBook",
        query: { [this.routerData]: locales_id }
      });
  } else {
    console.log("Seat selection incomplete. Staying on the current page.");
  }
},
   validateSeatSelection() {
  const totalFlights = this.totalSeat.length;
  const totalPassengers = this.totalpassanger.length;

  for (let passengerIndex = 0; passengerIndex < totalPassengers; passengerIndex++) {
    const passenger = this.totalpassanger[passengerIndex];

    if (passenger.type === "Infrant") {
      console.log("Skipping seat selection check for infant...");
      continue;
    }

    for (let flightIndex = 0; flightIndex < totalFlights; flightIndex++) {
      const hasCabins = this.totalSeat[flightIndex]?.cabins?.length > 0;
      console.log(this.totalSeat,'seatNumberrr...1')

      if (!hasCabins) {
        console.log(`Skipping seat validation for flight ${flightIndex} as it has no cabins.`);
        continue;
      }

      const seatNumber = passenger?.seat_number[flightIndex];
      console.log(seatNumber,hasCabins, 'seatNumberrr');
      
      if (!seatNumber) {
        this.dialog = true;
        return false;
      }
    }
  }

  return true;
},


  async submitform() {
    this.totalSeatData();
    this.pageloader=true
    let locales_id = this.local_id || this.Localoneway_id
    setTimeout(()=>{
      this.$router.push({
        path: "/confirmBook",
        query: { [this.routerData]: locales_id }
      });
    },3000)
    
  },
  translatePaxType(data){
      console.log(data,'data56')
      if(data == "Adult"){
        return this.$t("formsContents.options.adult")
      }
      else if(data == "Child"){
        return this.$t("formsContents.options.child")
      }
      else if(data == "Inf(lap)"){
        return this.$t("formsContents.options.infant")
      }
    },

  handleScroll() {
      if (
        this.lastPosition < window.scrollY &&
        this.limitPosition < window.scrollY
      ) {
        this.scrolled = true;
        this.topSticky = "5%";
      } else {
        this.scrolled = false;
        this.topSticky = "23%";
      }

      this.lastPosition = window.scrollY;
    },
    getData() {
      let localData = JSON.parse(localStorage.getItem("rountData"));
      this.fromdate = moment(localData.dedate).format("ddd, MMM D, YYYY");
      this.todate = moment(localData.redate).format("ddd, MMM D, YYYY");
      this.city = localData.city;

      if (localData.from) {
        this.from = localData.from.split(" ");
        this.from.forEach((v, i) => {
          if (i !== this.from.length - 1) {
            this.fromname = v;
            console.log(v, this.fromname, "tdtdtddtdttdd......");
          }
        });
        this.fromcode = this.from[this.from.length - 1]
          .replace("(", "")
          .replace(")", "");
      }

      if (localData.to) {
        this.to = localData.to.split(" ");
        this.to.forEach((v, i) => {
          if (i !== this.to.length - 1) {
            this.toname = v;
            console.log(v, this.toname, "ttsdtsdyusdsdsjd.....");
          }
        });

        this.tocode = this.to[this.to.length - 1]
          .replace("(", "")
          .replace(")", "");
      }

      // ------------->Check this Function<--------------
      if (localData.from.name) {
        this.from = localData.from.name.split(" ");
        this.from.forEach((v, i) => {
          if (i !== this.from.length - 1) {
            this.fromname += v;
          }
        });

        this.fromcode = this.from[this.from.length - 1]
          .replace("(", "")
          .replace(")", "");
      }

      if (localData.to.name) {
        this.to = localData.to.name.split(" ");
        this.tocode = this.to[this.to.length - 1]
          .replace("(", "")
          .replace(")", "");
      } else {
        this.to = localData.to.split(" ");
        this.tocode = this.to[this.to.length - 1]
          .replace("(", "")
          .replace(")", "");
      }
      // -------------><--------------

      // console.log(this.fromcode, " this.from");
      // console.log(this.tocode, "  this.to");
      // console.log(localData, "localData");
    },

    getadult() {
      this.getData();

      this.roundresult.forEach((v) => {
        console.log(v, "ddsdjvsdb");
        this.currency = v.currency;
        this.totalTaxPassanger = v.Taxprice;
        this.totalbasePassanger = v.Baseprice;
        this.totalAmountpassenger = v.TotalPrice;
        this.adultLength = v.Adult;
        this.childLength = v.Child;
        this.infLength = v.Inf;
        this.adult = v.Adult;
        this.child = v.Child;
        this.inf = v.Inf;
      });
    },

    onewayData() {
      this.getData();
      this.onewayresult.forEach((v) => {
        this.currency = v.currency;
        this.totalTaxPassanger = v.Taxprice;
        this.totalbasePassanger = v.Baseprice;
        this.totalAmountpassenger = v.TotalPrice;
        this.adultLength = v.Adult;
        this.childLength = v.Child;
        this.infLength = v.Inf;
        this.adult = v.Adult;
        this.child = v.Child;
        this.inf = v.Inf;
      });
    },

    getprice1() {
      let priceData = [];
      let rount = JSON.parse(localStorage.getItem("itineryData"));
      let oneway = JSON.parse(localStorage.getItem("onewayItineyData"));
      if (rount) {
        priceData = rount;
      }
      if (oneway) {
        priceData = oneway;
      }
      this.basePrice = priceData.price;
      this.taxPrice = priceData.Taxprice;
      // console.log(priceData, "priceData");
    },

    getprice(res) {
      return (res.price + res.Taxprice).toFixed(2);
    },
    multiData() {
      let res = this.local_id.split("-");
      let res1 = res[res.length - 1];
      let data_pass = [];
      data_pass = JSON.parse(localStorage.getItem(`${"multi"}-${res1}`));
      console.log(data_pass,res1, "data_pass");

      this.adultLength = data_pass.Adult;
      this.childLength = data_pass.Child;
      this.infLength = data_pass.Inf;
      this.adult = data_pass.Adult;
      this.child = data_pass.Child;
      this.inf = data_pass.Inf;

      let totalpass = [];
      totalpass = this.adultLength + this.childLength + this.infLength;
      console.log(totalpass, "totalpass");

      this.multiresult.forEach((v) => {
        this.currency = v.currency;
        this.totalTaxPassanger = v.Taxprice;
        this.totalbasePassanger = v.Baseprice;
        this.totalAmountpassenger = v.TotalPrice;
      });
    },

},

computed:{
  getDataResult() {
    if (this.roundresult && this.roundresult.length > 0) {
      return this.roundresult;
    } else if (this.onewayresult && this.onewayresult.length > 0) {
      return this.onewayresult;
    } else if (this.multiresult && this.multiresult.length > 0) {
      return this.multiresult;
    }

    return [];
  },
},

mounted(){
  this.passengerDetail();
},

created(){
  this.getroutequery = this.$route.query.pass_id1;
  console.log(this.getroutequery,'getroutequerygetroutequery');
    this.totalpassanger= JSON.parse(localStorage.getItem("totalpassanger"))
    if(this.$route.query.pass_id ){
        this.local_id = this.$route.query.pass_id 
        this.routerData="pass_id"
    }
    else if(this.$route.query.pass_id1){
        this.local_id =this.$route.query.pass_id1;
        this.routerData="pass_id1"
    }
    
   this.getTotalData = JSON.parse(
        localStorage.getItem(`${this.local_id}`)
      );  
    console.log(this.totalpassanger,this.local_id,this.routerData,this.getTotalData,'this.totalpassanger')
    this.getConfig();
    this.getseatMapping();

    let $data = localStorage.getItem("delete_Id");

    if ($data) {
      this.Localoneway_id = this.$route.query.pass_id1;
      this.local_id = this.$route.query.pass_id;

      // localStorage.removeItem(`${this.Localoneway_id}`);
      // localStorage.removeItem(`${this.local_id}`);
    }

    // let $data = localStorage.getItem("loader")
    // if ($data == "true") {
    //   this.$router.push('/')
    // }

    let $paxcontact = localStorage.getItem("passengerContact");
    // console.log($paxcontact, '$paxcontact')
    this.paxcontact = JSON.parse($paxcontact);
    // console.log(this.paxcontact[0].number, "ppppp");

    this.local_id = this.$route.query.pass_id || this.$route.query.pass_uid;
    this.Localoneway_id = this.$route.query.pass_id1;

    console.log(
      this.Localoneway_id,
      "this.local_id this.local_idthis.Localoneway_id "
    );
    console.log(this.local_id, "this.local_id env ");
    this.getConfig();

    if (this.Localoneway_id) {
      let onewaydata_uid = [];
      onewaydata_uid = JSON.parse(
        localStorage.getItem(`${this.Localoneway_id}`)
      );

      if (onewaydata_uid) {
        this.onewayresult.push(onewaydata_uid);
        this.onewayData();
        this.itineraryInfo1 = true;

        setTimeout(() => {
          this.dataloader = false;
        }, 3000);
      } else {
        this.dialog4 = true;
      }
    } else if (this.local_id) {
      if (this.local_id.includes("env")) {
        this.portal = true;
        let data_uid = [];
        data_uid = JSON.parse(localStorage.getItem(`${this.local_id}`));
        console.log(data_uid, "data_uiddata_uid");

        // if (!data_uid) {
        //   alert('Something went Wrong...')
        //   this.$router.push('/')
        // }
        console.log(data_uid, "data_uiddata_uiddata_uid");
        if (data_uid) {
          if (data_uid[0].Flight3 ? true : false) {
            this.multiresult = data_uid;
          } else if (data_uid[0].Return.length > 0 ? true : false) {
            this.roundresult = data_uid;
          } else {
            this.onewayresult = data_uid;
          }

          data_uid.forEach((v) => {
            this.local_id = v.selectId;
            this.currency = v.currency;
            let totalpass = [];

            this.adult = parseFloat(v.adult);
            this.child = parseFloat(v.child);
            this.inf = parseFloat(v.inf);
            this.adultLength = parseFloat(v.adult);
            this.childLength = parseFloat(v.child);
            this.infLength = parseFloat(v.inf);

            console.log(
              this.adult,
              "this.adult",
              this.child,
              "this.child",
              this.inf,
              "this.inf"
            );

            totalpass =
              parseFloat(v.adult) + parseFloat(v.child) + parseFloat(v.inf);
            console.log(totalpass, "totalpass");

            this.totalTaxPassanger = parseFloat(
              parseFloat(v.Taxprice) * totalpass
            ).toFixed(2);
            this.totalbasePassanger = parseFloat(
              parseFloat(v.Baseprice) * totalpass
            ).toFixed(2);
            this.totalAmountpassenger = parseFloat(
              parseFloat(this.totalbasePassanger) +
              parseFloat(this.totalTaxPassanger)
            ).toFixed(2);

            console.log(data_uid, "data_uiddata_uiddata_uid");
            console.log(v, "basePassangerbasePassangerbasePassanger");
            console.log(
              this.totalAmountpassenger,
              this.totalbasePassanger,
              this.totalTaxPassanger,
              "this.totalbasePassangerthis.totalbasePassanger"
            );
          });
          setTimeout(() => {
            this.dataloader = false;
          }, 3000);
        } else {
          console.log(this.dialog4, "this.dialog4......2");
          this.dialog4 = true;
          // console.log("Something went Wrong....1")
          // alert("Something went Wrong...");
          // this.$router.push("/");
        }
      } else if (this.local_id.includes("multi")) {
        let data_uid = [];
        data_uid = JSON.parse(localStorage.getItem(`${this.local_id}`));

        if (data_uid) {
          this.multiresult.push(data_uid);
          this.multiData();
          console.log(data_uid, "multicity");
          setTimeout(() => {
            this.dataloader = false;
          }, 3000);
        } else {
          this.dialog4 = true;
          console.log("Something went Wrong....2");
          // alert("Something went Wrong...");
          // this.$router.push("/");
        }
      } else if (this.local_id) {
        let data_uid1 = [];
        data_uid1 = JSON.parse(localStorage.getItem(`${this.local_id}`));
        console.log(data_uid1, "data_uid1data_uid1");

        if (data_uid1) {
          this.roundresult.push(data_uid1);
          this.getadult();
          this.itineraryInfo = true;
          setTimeout(() => {
            this.dataloader = false;
          }, 3000);
        } else {
          this.dialog4 = true;
          console.log("Something went Wrong....3");
          // alert("Something went Wrong...");
          // this.$router.push("/");
        }
      }
    } else {
      this.dialog4 = true;
      console.log(this.dialog4, "this.dialog4......3");
      // alert("Something went Wrong...");
      // this.$router.push("/");
    }
    if (this.roundresult.length > 0) {
      this.shoppingid = this.roundresult[0].ShoppingResponseId;
      this.selectId = this.roundresult[0].selectId;
    } else if (this.onewayresult.length > 0) {
      this.shoppingid = this.onewayresult[0].ShoppingResponseId;
      this.selectId = this.onewayresult[0].selectId;
    } else if (this.multiresult.length > 0) {
      this.shoppingid = this.multiresult[0].ShoppingResponseId;
      this.selectId = this.multiresult[0].selectId;
    }
}

}

</script>

<style scoped>
@import "@/assets/style.css";

.true-sheet {
  border: 2px solid green;
  border-radius: 5px;
  padding: 0px 3px;
  cursor: pointer;
  background: green;
}
.true-sheet:focus {
  background: green !important;
  border: 2px solid green !important;
}
.false-sheet {
  border: 2px solid #e2e2e2;
  border-radius: 5px;
  background: #e2e2e2;
  padding: 0px 3px;
}
.tru-icon {
  background: #26225e !important;
  border: 2px solid #26225e !important;
}
.tru-pass {
  background: #26225e !important;
  color: white !important;
  /* font-size:13px; */
}
.tru-flight {
  background: #26225e !important;
  color: white !important;
  padding: 5px;
}
.tru-flightfalse {
  padding: 5px;
  border: 1px solid gray;
}
.av-sheet {
  background: green;
  width: 17px;
  height: 17px;
  border: 2px solid green;
  margin: 3px;
  border-radius: 3px;
}
.sel-sheet {
  background: #26225e;
  width: 17px;
  height: 17px;
  border: 2px solid #26225e;
  margin: 3px;
  border-radius: 3px;
}
.oc-sheet {
  background: lightgray;
  width: 17px;
  height: 17px;
  border: 2px solid lightgray;
  margin: 3px;
  border-radius: 3px;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  /* border: 1px solid lightgray; */
  /* table-layout: fixed; */
}
td,
th {
  text-align: left;
  padding: 8px;
  word-wrap: break-word;
}
.fare-summery {
  position: sticky;
  width: 100%;
  z-index: 0;
  transition: top 0.5s;
}
.drawer-section .text-reset:hover {
  color: red !important;
  background: white;
  border-radius: 8px;
  height:18px;
}

:deep(.drawer-section .v-slide-group__content) {
  display: flex;
  justify-content: space-evenly;
}

:deep(.drawer-section .v-slide-group__content .v-btn) {
  height: 45px !important;
}
:deep(.drawer-section .v-slide-group__content .v-btn__content) {
  font-size: 12px;
}

:deep(.drawer-section .v-slide-group__content) {
  background: #f3f1f1;
  height: 50px;
}

:deep(.drawer-section .v-slide-group__content .v-slide-group-item--active .v-btn__content) {
  color: #26225e !important;
}

:deep(.drawer-section .v-timeline-divider__inner-dot) {
  background: #26225e !important;
}

:deep(.drawer-section .v-timeline--vertical.v-timeline) {
  grid-row-gap: 24px;
}

:deep(.drawer-section .v-timeline--vertical.v-timeline) {
  /* grid-row-gap: 24px; */
  height: auto !important;
}
.paxpricesection td{
  padding:8px !important;
}
hr{
  border-top:var(--bs-border-width) solid !important;
  margin-top:8px;
  margin-bottom:8px;
}
h6{
  color:black;
}
.selected{
  color:#26225e;
}
.fareDetails{
  margin-bottom:70px;
}
.drawer-section .offcanvas{
  width:35% !important;
}
@media screen and (max-width:480px) {
  .fareDetails{
    margin-bottom:20px !important;
  }
  .drawer-section .offcanvas{
    width:100% !important;
  }
}
@media only screen and (min-width: 768px) {
  .fare-show {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .fare-show v-card {
    max-width: 0px;
  }
}
@media screen and (max-width: 992px) {
  .fare-show {
    display: block;
  }
}

/* @media only screen and (max-width: 769px) {
  .fare-summery {
    display: none;
  }
  .fare-summery1 {
    display: none;
  }
} */
 >>> .cancellationRules .cancellation_content__2apKn .FareRule h2 {
  font-size: 17px !important;
  font-weight: 700 !important;
}
>>> .cancellationRules .cancellation_content__2apKn .FareRule p {
  overflow-wrap: break-word !important;
  /* inline-size: 58%; */
}
>>> .cancellationRules .cancellation_content__2apKn .FareRule ul li {
  overflow-wrap: break-word !important;
  /* inline-size: 60%; */
}
>>> .cancellationRules .cancellation_content__2apKn .FareRule form ul {
  height: 100%;
}
>>> .drawer-section .v-slide-group__content {
  display: flex;
  justify-content: center !important;
}

>>> .v-slide-group__content .v-btn {
  height: 45px !important;
}

>>> .v-slide-group__content .v-btn__content {
  font-size: 12px;
}

>>> .drawer-section .v-slide-group__content {
  background: #f3f1f1;
  height: 50px;
}

>>> .drawer-section
  .v-slide-group__content
  .v-slide-group-item--active
  .v-btn__content {
  color: #002d5b;
}
>>> .v-tabs--density-default.v-tabs--stacked {
  --v-tabs-height: 58px;
}
>>> .v-tabs--fixed-tabs .v-slide-group__content > *:last-child,
.v-tabs--align-tabs-center .v-slide-group__content > *:last-child {
  margin-inline-end: inherit;
}
</style>
